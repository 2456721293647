import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import PasswordChecklist from "react-password-checklist";
import Swal from 'sweetalert2';
import { logout, selectUser } from '../../redux/Userslice';
import { changePassword } from '../../services/changePasswordServices';
import { useDispatch, useSelector } from "react-redux";
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { useThemeOutlet } from '../../context/ThemeContext';

function ChangePassword(props) {

   
    const { show, handleClose } = props;
    const { register, handleSubmit, reset, watch, formState: { errors }, setError, clearErrors } = useForm();
    const oldPassword = watch('oldPassword', '');
    const newPassword = watch('newPassword', '');
    const confirmPassword = watch('confirmPassword', '');
    const [isDisabled, setIsDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const userData = useSelector(selectUser);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showComPassword, setShowComPassword] = useState(false);
    const { themeOutlet, toggleThemeOutlet } = useThemeOutlet();
    const handleModalClose = () => {
        clearErrors();
        handleClose(false);
        reset();
    };

    useEffect(() => {
        if (oldPassword && newPassword && oldPassword === newPassword) {
            setError("newPassword", {
                type: "manual",
                message: "New password must be different from the old password.",
            });
        } else {
            clearErrors("newPassword");
        }

        if (newPassword && confirmPassword && newPassword !== confirmPassword) {
            setError("confirmPassword", {
                type: "manual",
                message: "Passwords don't match",
            });
        } else {
            clearErrors("confirmPassword");
        }

    }, [oldPassword, newPassword, confirmPassword, setError, clearErrors]);

    const onSubmit = (data) => {
        const { oldPassword, newPassword, confirmPassword } = data;

        if (oldPassword === newPassword) {
            setError("newPassword", {
                type: "manual",
                message: "New password must be different from the old password.",
            });
            return;
        }

        if (newPassword !== confirmPassword) {
            setError("confirmPassword", {
                type: "manual",
                message: "Passwords don't match",
            });
            return;
        }

        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/;
        if (!passwordRegex.test(newPassword)) {
            setError("newPassword", {
                type: "manual",
                message: 'Password must be at least 8 characters long with at least one uppercase letter, one lowercase letter, and one numeric digit.',
            });
            return;
        }
        setIsDisabled(true);
        Swal.fire({
            title: 'Are you sure want to',
            text: "Change your Password?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            customClass: {
                popup: `${themeOutlet} custom-swal-popup`
              }
        }).then((result) => {
            if (result.isConfirmed) {
                let formData = new FormData();
                formData.append("oldPassword", oldPassword);
                formData.append("newPassword ", newPassword);
                changePassword(formData).then((res) => {
                    if (res.status === 1) {
                        Swal.fire({
                            title: res.message,
                            icon: 'success',
                            timer: 2000,
                            customClass: {
                                popup: `${themeOutlet} custom-swal-popup`
                              }
                        });
                        dispatch(logout());
                        handleModalClose();
                    }
                    else {
                        Swal.fire({
                            title: res.message,
                            icon: 'error',
                            customClass: {
                                popup: `${themeOutlet} custom-swal-popup`
                              }
                        });
                    }
                }).catch((error) => {
                    console.error('Password change failed:', error);
                    setError("submitError", { message: 'An error occurred while changing the password.' });
                }).finally(() => {
                    setLoading(false);
                    setIsDisabled(false);
                });
            } else {
                setIsDisabled(false);
            }
        });
    };

    useEffect(() => {
        setIsDisabled(false);
    }, [show]);

    return (
        <Modal show={show} onHide={handleModalClose} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton className="mb-3 bg-color">
                <Modal.Title className='text-white'>Change Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit(onSubmit)} className="mb-5">
                    <Form.Group className="mb-3" controlId="formBasicOldPassword">
                        <Form.Label>Old Password<span className='text-danger'>*</span></Form.Label>
                        <div className="password-rel">
                            <Form.Control
                                type={showOldPassword ? "text" : "password"}
                                placeholder="Enter old password"
                                className="newSize"
                                {...register("oldPassword", { required: "Old password is required" })}
                                isInvalid={!!errors.oldPassword}
                            />
                            <div
                                className="eye-icon"
                                onClick={() => setShowOldPassword((prevShowPassword) => !prevShowPassword)}
                            >
                                {showOldPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                            </div>
                        </div>
                        <p type="invalid" className="small text-danger mt-1 mb-0">
                            {errors.oldPassword?.message}
                        </p>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicNewPassword">
                        <Form.Label>New Password<span className='text-danger'>*</span></Form.Label>
                        <div className="password-rel">
                            <Form.Control
                                type={showNewPassword ? "text" : "password"}
                                placeholder="Enter new password"
                                className="newSize"
                                {...register("newPassword", { required: "New password is required" })}
                                isInvalid={!!errors.newPassword}
                            />
                            <div
                                className="eye-icon"
                                onClick={() => setShowNewPassword((prevShowPassword) => !prevShowPassword)}
                            >
                                {showNewPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                            </div>
                        </div>
                        <p type="invalid" className="small text-danger mt-1 mb-0">
                            {errors.newPassword?.message}
                        </p>
                    </Form.Group>
                    <div className="mb-3">
                        <PasswordChecklist
                            rules={['minLength', 'specialChar', 'number', 'capital', 'lowercase', 'match']}
                            minLength={8}
                            value={newPassword}
                            valueAgain={confirmPassword}
                            messages={{
                                minLength: 'Password must have more than 8 characters.',
                                specialChar: 'Password must have at least one special character.',
                                number: "Password must have at least one number ('0'-'9').",
                                capital: "Password must have at least one uppercase ('A'-'Z').",
                                lowercase: "Password must have at least one lowercase ('a'-'z').",
                                match: 'Password matches.',
                            }}
                        />
                    </div>
                    <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
                        <Form.Label>Confirm Password<span className='text-danger'>*</span></Form.Label>
                        <div className="password-rel">
                            <Form.Control
                                type={showComPassword ? "text" : "password"}
                                placeholder="Confirm new password"
                                className="newSize"
                                {...register("confirmPassword", { required: "Confirm password is required" })}
                                isInvalid={!!errors.confirmPassword}
                            />
                            <div
                                className="eye-icon"
                                onClick={() => setShowComPassword((prevShowPassword) => !prevShowPassword)}
                            >
                                {showComPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                            </div>
                        </div>
                        <p type="invalid" className="small text-danger mt-1 mb-0">
                            {errors.confirmPassword?.message}
                        </p>
                    </Form.Group>
                </Form>
                <Button className='btn-custom' variant="primary" type="submit" style={{ float: "right" }} onClick={handleSubmit(onSubmit)} disabled={isDisabled}>Submit</Button>
            </Modal.Body>
        </Modal>
    );
}

export default ChangePassword;
