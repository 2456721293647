import axios from 'axios';
// config/axiosConfig/axiosConfig.js
const getToken = () => {
  const token = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).token : null;
  return token;
};

const getSchema = () => {
  return sessionStorage.getItem('schema') ? sessionStorage.getItem('schema') : null;
};


// export const headers = header;
export const headers = () => {
  let schema = getSchema();
  let header = {
    'Content-Type': 'application/json',
    'X-TenantID': null,
  };
  if (schema ) {
    header = {
      'Content-Type': 'application/json',
      'X-TenantID': schema,
    };
  }
  return header;
};

export const headersForJwtWithFormdata = () => {
  const token = getToken();
  return {
    'Authorization': `Bearer ${token}`,
  };
};

export const headersForPDF = () => {
  const token = getToken();
  return {
    'Authorization': `Bearer ${token}`,
    'responseType': 'blob',
  };
};

export const headersForJwtWithJson = () => {
  const token = getToken();
  return {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
};

export const headersForJwtQueryParam = () => {
  const token = getToken();
  return {
    'Authorization': `Bearer ${token}`,
    'accept': '*/*',
  };
};

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
