import { createSlice } from '@reduxjs/toolkit';

const initialTheme = localStorage.getItem('theme') ? localStorage.getItem('theme') : 'light';

export const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    theme: initialTheme,
  },
  reducers: {
    setDefaultTheme: (state, action) => {
      state.theme = action.payload;
      localStorage.setItem('theme', action.payload);
    },
  },
});

export const { setDefaultTheme } = themeSlice.actions;
export const selectTheme = (state) => state.theme.theme;
export default themeSlice.reducer;
