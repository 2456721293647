import React, { createContext, useState, useEffect, useContext } from 'react';

import { useDispatch, useSelector } from "react-redux";
import { setThemeOutlet } from '../redux/themeOutletSlice';
import { selectTheme } from '../redux/themeSlice';
import { setDefaultTheme } from '../redux/themeSlice';
import { selectThemeOutlet } from '../redux/themeOutletSlice';

const ThemeContext = createContext();

// eslint-disable-next-line react/prop-types
export const ThemeProvider = ({ children }) => {
  const dispatch = useDispatch();
  // const initialTheme = useSelector(selectTheme) || 'light';
  const initialThemeOutlet = useSelector(selectThemeOutlet) || 'light';
  const [themeOutlet, setSelectedThemeOutlet] = useState(initialThemeOutlet);
  const storedTheme = useSelector(selectTheme); // Move useSelector call outside the function
  
  useEffect(() => {
    dispatch(setThemeOutlet(themeOutlet));
  }, [themeOutlet]);

  const toggleThemeOutlet = () => {
    setSelectedThemeOutlet((prevTheme) => {
      const newThemeSet = prevTheme === 'dark' ? storedTheme : 'dark';
      return newThemeSet; // Default to the first theme in the new set
    });
  };

  const setTheme = (theme) => {
    dispatch(setDefaultTheme(theme));
    setSelectedThemeOutlet((prevTheme) => {
      const newThemeSet = prevTheme === 'dark' ? 'dark' : theme;
      return newThemeSet; // Default to the first theme in the new set
    });
  };

  return (
    <ThemeContext.Provider value={{ themeOutlet, toggleThemeOutlet, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeOutlet = () => useContext(ThemeContext);
