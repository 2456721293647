import React from "react";
import * as LuIcons from "react-icons/lu";

const DynamicIcon = ({ iconName, activeMenu, menuName }) => {
  // Get the icon component based on the icon name
  const IconComponent = LuIcons[iconName] || LuIcons.LuUserCircle; // Default to LuUserCircle if icon name not found
  const iconClass = activeMenu === menuName ? `red` :`yellow`;
  return <IconComponent size={15} className={iconClass}/>;
};

export default DynamicIcon;
