/**
 * @author SPARC PRIVATE LIMITED
 */

import React from "react";
import "./style.css";
import { useThemeOutlet } from "../../context/ThemeContext";

const SuspenseFallback = () => {
  const { themeOutlet, toggleThemeOutlet } = useThemeOutlet();
  return (
    <div className={`cover-container-box rounded-top-4 ${themeOutlet}`}>

 
    <div className="loader">
      <div className="inner one"></div>
      <div className="inner two"></div>
      <div className="inner three"></div>
    </div>
    </div>
  );
};

export default SuspenseFallback;
