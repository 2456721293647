import React from 'react';

const BrokenImageChecker = ({ imageLink, width, height ,alt, roundImage}) => {
  const handleError = (event) => {
    event.target.src =  "./usericon.svg";
  };

  return (
    <img
      className= {roundImage ? "rounded-circle": ""}
      src={imageLink}
      onError={handleError}
      width={width}
      height={height}
      alt={alt}
    />
  );
};

export default BrokenImageChecker;
