import { createSlice } from '@reduxjs/toolkit';

const initialThemeOutlet = localStorage.getItem('themeOutlet') ? localStorage.getItem('themeOutlet') : 'light';

export const themeOutletSlice = createSlice({
  name: 'themeOutlet',
  initialState: {
    themeOutlet: initialThemeOutlet,
  },
  reducers: {
    setThemeOutlet: (state, action) => {
      state.themeOutlet = action.payload;
      localStorage.setItem('themeOutlet', action.payload);
    },
  },
});

export const { setThemeOutlet } = themeOutletSlice.actions;
export const selectThemeOutlet = (state) => state.themeOutlet.themeOutlet;
export default themeOutletSlice.reducer;
