import React, { useState, useEffect, useMemo } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  menuClasses,
  MenuItemStyles,
} from "react-pro-sidebar";
import { SidebarHeader } from "./components/SidebarHeader.tsx";
import { SidebarFooter } from "./components/SidebarFooter.tsx";
import { Outlet } from "react-router";
import "./sidebar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectUser } from "../../redux/Userslice.js";
import { selectThemeOutlet, setThemeOutlet, themeOutletSlice } from "../../redux/themeOutletSlice.js";
import themeSlice from "../../redux/themeSlice.js";
import DynamicIcon from "./icons/DynamicIcon.jsx";
import Navigation from "../../pages/layouts/Navigation.js";
import Switch from "react-switch";
import DynamicMenu from "./DynamicMenu.jsx";
import { useThemeOutlet } from "../../context/ThemeContext.js";
// import Clogo from './logo-icon.svg';
// import { selectUser } from '../../redux/Userslice.js';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
type Theme = "light" | "dark" | "theme_2" | "theme_3";

export const themes = {
  light: {
    sidebar: {
      backgroundColor: "#EFF7FF",
      color: "#607489",
    },
    menu: {
      menuContent: "#fbfcfd",
      icon: "#7839CD",
      hover: {
        backgroundColor: "#c5e4ff",
        color: "#44596e",
      },
      disabled: {
        color: "#9fb6cf",
      },
    },
    main: {
      backgroundColor: "#F6F7FB",
      color: "#000",
    },
    navigation: {
      backgroundColor: "#F6F7FB",
      color: "#000000",
    },
  },

  dark: {
    sidebar: {
      backgroundColor: "#10101a",
      color: "#8ba1b7",
    },
    menu: {
      menuContent: "#082440",
      icon: "#59d0ff",
      hover: {
        backgroundColor: "#00458b",
        color: "#b6c8d9",
      },
      disabled: {
        color: "#3e5e7e",
      },
    },
    main: {
      backgroundColor: "#222133",
      color: "#aaa",
    },
    navigation: {
      backgroundColor: "#0b2948",
      color: "#ffffff",
    },
  },

  theme_2: {
    sidebar: {
      backgroundColor: "#344C64",
      color: "#8ba1b7",
    },
    menu: {
      menuContent: "#082440",
      icon: "#59d0ff",
      hover: {
        backgroundColor: "#344C64",
        color: "#b6c8d9",
      },
      disabled: {
        color: "#3e5e7e",
      },
    },
    main: {
      backgroundColor: "#344C64",
      color: "#aaa",
    },
    navigation: {
      backgroundColor: "#0b2948",
      color: "#ffffff",
    },
  },

  theme_3: {
    sidebar: {
      backgroundColor: "#e6f1f5",
      color: "#8ba1b7",
    },
    menu: {
      menuContent: "#082440",
      icon: "#59d0ff",
      hover: {
        backgroundColor: "#e6f1f5",
        color: "#b6c8d9",
      },
      disabled: {
        color: "#3e5e7e",
      },
    },
    main: {
      backgroundColor: "#e6f1f5",
      color: "#aaa",
    },
    navigation: {
      backgroundColor: "#0b2948",
      color: "#ffffff",
    },
  },

};

// hex to rgba converter
const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const useTheme = (): [Theme, () => void] => {
  const dispatch = useDispatch();
  const storedTheme = useSelector(selectThemeOutlet) as Theme;
  const [theme, setTheme] = useState<Theme>(() => (storedTheme === 'dark' ? 'dark' : 'light'));

  useEffect(() => {
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, [storedTheme]);

  const toggleTheme = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    setTheme(newTheme);
    dispatch(setThemeOutlet(newTheme));
  };

  return [theme, toggleTheme];
};


const Playground: React.FC = () => {
  const [userData, setUserDate] = React.useState(useSelector(selectUser));
  const [collapsed, setCollapsed] = React.useState(false);
  const [toggled, setToggled] = React.useState(false);
  const [broken, setBroken] = React.useState(false);
  const [rtl, setRtl] = React.useState(false);
  const [hasImage, setHasImage] = React.useState(false);
  // const [theme, setTheme] = React.useState<Theme>("light");
  const [theme, toggleTheme] = useTheme();
  const { themeOutlet, toggleThemeOutlet } = useThemeOutlet();
  const [activeMenu, setActiveMenu] = React.useState("");
  const [mapCollapsed, setMapCollapsed] = React.useState(false);

  const pathname = window.location.pathname;

  useEffect(() => {
    const findMenuItem = (menuItems, path) => {
      for (let item of menuItems) {
        if (item.module === path) {
          return item;
        }
        if (item.child && item.child.length > 0) {
          const foundChild = findMenuItem(item.child, path);
          if (foundChild) {
            return foundChild;
          }
        }
      }
      return null;
    };

    const matchedMenuItem = findMenuItem(userData.menu, pathname);
    if (matchedMenuItem) {
      // Only mapview will Collapsed while open
      if (activeMenu === "Map View" && mapCollapsed === true) {
        setCollapsed(false);
        setMapCollapsed(false);
      } else if (
        matchedMenuItem.menuName === "Map View" &&
        collapsed === false &&
        mapCollapsed === false
      ) {
        setCollapsed(true);
        setMapCollapsed(true);
      }
      // set the menu active
      setActiveMenu(matchedMenuItem.menuName);
    }
  }, [pathname, userData.menu, setActiveMenu]);


  let navigate = useNavigate();
  // handle on RTL change event
  const handleRTLChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRtl(e.target.checked);
  };

  // handle on theme change event
  // const handleThemeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  // };

  const handleThemeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    toggleThemeOutlet();
    toggleTheme();
  };

  // handle on image change event
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasImage(e.target.checked);
  };

  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: "13px",
      fontWeight: 400,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(
              themes[theme].menu.menuContent,
              hasImage && !collapsed ? 0.4 : 1
            )
          : "transparent",
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      "&:hover": {
        backgroundColor: hexToRgba(
          themes[theme].menu.hover.backgroundColor,
          hasImage ? 0.8 : 1
        ),
        color: themes[theme].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  let sidebarContentListing;
  if (userData.menu.length > 0) {
    sidebarContentListing =
      userData.menu.length > 0 ? (
        userData.menu.map((menu, menuIndex) => (
          <Menu menuItemStyles={menuItemStyles} key={menuIndex}>
            {menu.child.length > 0 ? (
             <SubMenu
             label={menu.menuName}
             icon={
               <DynamicIcon
                 iconName={menu.menuIcon}
                 activeMenu={activeMenu}
                 menuName={menu.menuName}
               />
             }
           >
             {menu.child.map((child, index) => 
             collapsed ? (
                 <OverlayTrigger
                   key={index}
                   placement="right"
                   delay={{ show: 250, hide: 400 }}
                   overlay={(props) => renderTooltip(props, child.menuName)}
                 >
                   <MenuItem
                     key={index}
                     title={child.menuName}
                     icon={
                       <DynamicIcon
                         iconName={child.menuIcon}
                         activeMenu={activeMenu}
                         menuName={menu.menuName}
                       />
                     }
                     onClick={(e) =>
                       onClickMenuItem(e, child.module, menu.menuName, child)
                     }
                     className={
                       activeMenu === child.menuName
                         ? `${themeOutlet} active`
                         : ""
                     }
                   >
                     {child.menuName}
                   </MenuItem>
                 </OverlayTrigger>
               ) : (
                 <MenuItem
                   key={index}
                   title={child.menuName}
                   icon={
                     <DynamicIcon
                       iconName={child.menuIcon}
                       activeMenu={activeMenu}
                       menuName={menu.menuName}
                     />
                   }
                   onClick={(e) =>
                     onClickMenuItem(e, child.module, menu.menuName, child)
                   }
                   className={
                     activeMenu === child.menuName
                       ? `${themeOutlet} active`
                       : ""
                   }
                 >
                   {child.menuName}
                 </MenuItem>
               )
             )}
           </SubMenu>
            ) : (
              <>
              {collapsed ? (
                <OverlayTrigger
                  key={menuIndex}
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) => renderTooltip(props, menu.menuName)}
                >
                  <Menu menuItemStyles={menuItemStyles}>
                    <MenuItem
                      icon={
                        <DynamicIcon
                          iconName={menu.menuIcon}
                          activeMenu={activeMenu}
                          menuName={menu.menuName}
                        />
                      }
                      onClick={(e) =>
                        onClickMenuItem(e, menu.module, menu.menuName, menu)
                      }
                      className={
                        activeMenu === menu.menuName
                          ? `${themeOutlet} active`
                          : ""
                      }
                    >
                      {menu.menuName}
                    </MenuItem>
                  </Menu>
                </OverlayTrigger>
              ) : (
                <MenuItem
                  key={menuIndex}
                  icon={
                    <DynamicIcon
                      iconName={menu.menuIcon}
                      activeMenu={activeMenu}
                      menuName={menu.menuName}
                    />
                  }
                  onClick={(e) =>
                    onClickMenuItem(e, menu.module, menu.menuName, menu)
                  }
                  className={
                    activeMenu === menu.menuName
                      ? `${themeOutlet} active`
                      : ""
                  }
                >
                  {menu.menuName}
                </MenuItem>
              )}
            </>
            )}
          </Menu>
        ))
      ) : (
        <></>
      );
  }
  const onClickMenuItem = (e, targetUrl, labelName, menuId) => {
    // console.log(labelName);
    // console.log(targetUrl);
    e.preventDefault();
    navigate(`${targetUrl}`);
    // if (targetUrl !== '/manageSurveyThreshold') {
    // 	navigate(`${targetUrl}`);

    // 	// setActiveMenu(labelName);
    // 	// navigate({
    // 	//   pathname: `${targetUrl}`,
    // 	//   state: { menuId: menuId?.value },
    // 	// });
    // }
  };


  const renderTooltip = (props, label) => (
    <Tooltip id="button-tooltip" {...props}>
      {label}
    </Tooltip>
  );
  


  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        direction: rtl ? "rtl" : "ltr",
      }}
    >
      <Sidebar
        className="menu-scroll style-8"
        // style={{border:'1px solid #434' ,position:'sticky'}}
        collapsed={collapsed}
        toggled={toggled}
        onBackdropClick={() => setToggled(false)}
        onBreakPoint={setBroken}
        // image="https://user-images.githubusercontent.com/25878302/144499035-2911184c-76d3-4611-86e7-bc4e8ff84ff5.jpg"
        rtl={rtl}
        breakPoint="md"
        backgroundColor={hexToRgba(
          themes[theme].sidebar.backgroundColor,
          hasImage ? 0.9 : 1
        )}
        rootStyles={{
          color: themes[theme].sidebar.color,
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <SidebarHeader
            rtl={rtl}
            style={{ marginBottom: "24px", marginTop: "16px" }}
          />
          <div style={{ flex: 1, marginBottom: "32px" }}>
            {/* <DynamicMenu menuItemStyles={menuItemStyles} theme={theme} /> */}

            {sidebarContentListing}
          </div>
          {/* <div style={{width:'50px',height:'50px', marginLeft:'1.5em'}}>
						<img src={Clogo} alt="client logo" />
					</div> */}
         <SidebarFooter collapsed={collapsed}/>
        </div>
       
      </Sidebar>

      <main
        className={`${themeOutlet}  px-lg-4 px-sm-0  w-100 main-container-bg full style-8`}
        style={{
          height: "100vh",
          overflowY: "auto",
          position: "sticky",
          backgroundColor: themes[theme].main.backgroundColor,
          color: themes[theme].main.color,
        }}
      >
        <Navigation
          setCollapsed={setCollapsed}
          collapsed={collapsed}
          toggled={toggled}
          setToggled={setToggled}
          theme={theme}
          Switch={Switch}
          handleThemeChange={handleThemeChange}
        />
        <Outlet />
      </main>
      {/* <main className="px-4 py-4">
				<Outlet />
			</main> */}
      {/* <div style={{ position: "fixed", top: 20, right: 150, zIndex: 1000 }}>
        <Switch onChange={handleThemeChange} checked={theme === "dark"} />
      </div> */}
    </div>
  );
  
};

export default Playground;
