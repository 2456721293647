// redux/Userslice.js
import { createSlice } from '@reduxjs/toolkit';
// import { createSelector } from 'reselect';
export const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
    },
    reducers: {
        login: (state, action) => {
            localStorage.setItem('user', JSON.stringify(action.payload));
            state.user = action.payload;
        },
        logout: (state) => {
            state.user = null;
            localStorage.clear();
            sessionStorage.clear();
        },
    },
});

export const { login, logout } = userSlice.actions;
export const selectUser = (state) => state.user.user;

export default userSlice.reducer;

