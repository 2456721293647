// redux/projectSlice.js
import { createSlice } from '@reduxjs/toolkit';


export const projectSlice = createSlice({
    name: 'project',
    initialState: {
        projectDetails: localStorage.getItem('projectDetails') ? JSON.parse(localStorage.getItem('projectDetails')) : null,
    },
    reducers: {
        setProjectDetails: (state, action) => {
            localStorage.setItem('projectDetails', JSON.stringify(action.payload));
            state.projectDetails = action.payload;
        },
        clearProjectDetails: (state) => {
            state.projectDetails = null;
            localStorage.removeItem('projectDetails');
        },
    },
});


export const { setProjectDetails, clearProjectDetails } = projectSlice.actions;
export const selectProjectDetails = (state) => state.project.projectDetails;

export default projectSlice.reducer;
