import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../redux/Userslice";
import projectReducer from "./projectSlice";
import themeReducer from './themeSlice';
import themeOutletReducer from './themeOutletSlice';
export default configureStore({
    reducer:{
        user: userReducer,
        project: projectReducer,
        theme: themeReducer,
        themeOutlet: themeOutletReducer,
    },
})